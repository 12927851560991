<template>
    <div class="home">
        <h1>Welcome!</h1>
        <p>您是系统的第<span class="visit-count">{{ visitNum }}</span>位访客<span class="trick">骗你的</span></p>
        <p>更多内容请参阅<a href="/blog">博客</a>页面</p>
    </div>
</template>

<script>
export default {
    name: 'Home',
    data () {
        return {}
    },
    methods: {},
    computed: {
        visitNum () {
            let origin = parseInt(localStorage.getItem('visit-count') || '0')
            if (origin === 0) {
                origin = Math.floor(Math.random() * 4500)
            }
            let current = origin + Math.floor(Math.random() * 10)
            localStorage.setItem('visit-count', current)
            return current
        }
    }
}
</script>

<style scoped>
.home {
    width: 275px;
    margin: 0 auto;
}

.visit-count {
    padding: 0 6px;
    font-weight: bold;
}

a, a:visited, a:hover {
    padding: 0 4px;
    color: deepskyblue;
    font-weight: bold;
    text-decoration: none;
}

</style>
